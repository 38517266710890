.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-control {
  border-radius: 0 !important;
  padding: 10px 15px;
  border: none;
  border-bottom: 1px solid #211f26;
  font-size: 16px;
  background-color: #f5f5f5;
  display: block;
  width: 270px;
}

.form-group {
  margin-bottom: 20px;
}

.btn {
  border-radius: 0;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  background-color: #619c8a;
  color: white;
  font-weight: 500;
}

.login-form {
}

.login-form-wrapper {
  width: 300px;
  background: white;
  padding: 40px 60px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25vh;
  margin-bottom: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

body {
  background: #b6bab4;
  font-family: "Lato";
}

.title-style-1 {
  font-size: 24px;
  font-weight: 500;
}

.button {
  border-radius: 4px;
  padding: 10px 20px;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  background-color: #0f191d;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.navbar {
  height: 100vh;
  width: 200px;
  background-color: #3a4b53;
  padding: 50px 40px;
  position: fixed;
  top: 0;
}

.page-wrapper {
  display: flex;
  flex-direction: row;
  background-color: #263238;
}

.page-content {
  flex-grow: 1;
  margin: 30px;
  padding: 30px;
  background-color: #3a4b53;
  border-radius: 4px;
  margin-left: 310px;
  min-height: calc(100vh - 120px);
}

.nav-item {
  margin-bottom: 4px;
}

.nav-item a {
  display: block;
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  padding: 10px 8px;
  text-align: left;
}

.nav-item a.active,
.nav-item a:hover {
  background-color: #2e3c43;
  border-radius: 4px;
  transition: ease-in-out 0.3s;
}

.list {
  color: #fff;
  list-style-type: none;
}

.list-item {
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.list-item:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.list-cell {
  padding: 10px 8px;
  flex-basis: 20%;
  text-align: left;
}

.list-cell--medium {
  flex-basis: 12%;
}

.list-cell--small {
  flex-basis: 8%;
}

.list-cell--xsmall {
  flex-basis: 4%;
}

.file-input--hidden {
  visibility: hidden;
  max-width: 0;
  max-height: 0;
  overflow: hidden;
}

.button--large {
  padding: 40px 40px 40px 40px;
  display: inline-block;
}